import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';

const AboutUsPage = () => {
  return (
    <Layout>
      <Seo title="Über Uns" />

      <Hero>
        <div className="row">
          <div className="col-sm">
            <h1>Über Uns</h1>
            <p className="lead">Die projekt spielart GmbH entstand aus einem Künstlerkollektiv.</p>
            <p>Wir bieten einmalige Gestaltung, Qualität und hohen Spielwert unserer Spielskulpturen - <strong>alles Unikate, keine Serienprodukte</strong>.</p>
            <p>Unsere künstlerische Ideenfindung und unser großes handwerkliches Können sind unser Markenzeichen.</p>
          </div>
          <div className="col-sm-5">
            <figure className="figure">
              <StaticImage
                src="../images/content/projekt-spielart-team.jpg"
                alt="Das Projekt Spielart Team"
                imgClassName="img-fluid rounded"
              />
              <figcaption className="figure-caption">Unser Team vor der Werkstatt</figcaption>
            </figure>
          </div>
        </div>
      </Hero>

      <div className="container-xl">
        <h2>Geschichte</h2>
        <p className="lead">Das Künstlerkollektiv zwischen Harald Thaler und Heinrich Hofmann begann im Jahr 2000.</p>
        <p>Die Idee "Projekt Spielart" und die ersten Spielskupturen entstanden 2004. Seitdem haben wir zahlreiche Projekte in Deutschland, Österreich, Schweiz und Italien mit großem Erfolg und zur Freude von Kindern und Eltern umgesetzt.</p>

        <div className="row my-5">
          <div className="col-sm">
            <h3 className="fs-4">Heinrich Hofmann</h3>
            <ul>
              <li>freischaffender Bildhauer, Maler und Performer seit 1979</li>
              <li>1983 Gründung Künstlergemeinschaft Rosenheim mit Ludwig Frank und Roland Mayer</li>
              <li>1993 Gründung europäische Künstlergruppe <a href="https://eag-frequenzen.org" target="_blank" rel="noreferrer">"Frequenzen"</a></li>
              <li>1996 - 2003 Spielplatzbau mit Florian Aigner</li>
            </ul>
          </div>
          <div className="col-sm">
            <h3 className="fs-4">Harald Thaler</h3>
            <ul>
              <li>geb. 19.05.1974</li>
              <li>Bildhauerschule Südtirol/Ahrntal</li>
              <li>seit 2000 freischaffender Künstler und Holzbildhauer</li>
              <li>seit 2004 Projekt Spielart mit Heiner Hofmann</li>
              <li>diverse Symposien in Europa</li>
              <li>Mitglied der europäischen Künstlergruppe „Frequenzen“</li>
            </ul>
          </div>
          <div className="col-sm">
            <h3 className="fs-4">Florian Hofmann</h3>
            <ul>
              <li>geboren am 13.07.1993 in Rosenheim</li>
              <li>2010 - 2013 Ausbildung zum Holzbildhauer</li>
              <li>2013 Landessieger der Holzbildhauer</li>
              <li>seit 2010 bei Projekt Spielart</li>
              <li>diverse eigene Kunstobjekte</li>
            </ul>
          </div>
        </div>

        <h2>Bespielbare Skulpturen</h2>
        <div className="row my-5">
          <div className="col-sm">
            <figure className="figure">
              <StaticImage
                src="../images/content/individuelle-gestaltung.jpg"
                alt="Bespielbare Skulpturen mit einzigartigen Klettereinbauten"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Vogelwuid, Gräfelfing bei München</figcaption>
            </figure>
            <h3 className="fs-4">Individuelle Gestaltung</h3>
            <p>Unsere Kunstwerke entstehen ausschließlich für den bestimmten Platz und dessen Umfeld. Jedes Projekt Spielart ist ein wahres Unikat!</p>
          </div>
          <div className="col-sm">
            <figure className="figure">
              <StaticImage
                src="../images/content/regionaler-bezug.jpg"
                alt="Regionaler Bezug"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Erinnerung an die alten Anbautraditionen im südtiroler Weinbau</figcaption>
            </figure>
            <h3 className="fs-4">Regionaler Bezug</h3>
            <p>Wir entwickeln unsere Ideen durch persönliche Eindrücke vor Ort. Geschichtliche, kulturelle oder geologische Besonderheiten sind ein wichtiger Faktor bei unserer Ideenfindung.</p>
          </div>
          <div className="col-sm">
            <figure className="figure">
              <StaticImage
                src="../images/content/spielanreiz.jpg"
                alt="Spielanreiz"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Moskito BUGA 2005 München</figcaption>
            </figure>
            <h3 className="fs-4">Spielanreiz</h3>
            <p>Spaß am Spiel ist garantiert. Wir arbeiten oft eng mit den Kindern zusammen die den Spielplatz nutzen und konnten damit wertvolle Erfahrung sammeln.</p>
            <p>Wir wissen wie die Kids ticken - unsere einmaligen Bauten laden auf den ersten Blick zum Spielen und Klettern ein!</p>
          </div>
          <div className="col-sm">
            <figure className="figure">
              <StaticImage
                src="../images/content/klettereinbauten.jpg"
                alt="Klettereinbauten"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Kletterbeispiel</figcaption>
            </figure>
            <h3 className="fs-4">Einmalige Klettereinbauten</h3>
            <p>Für geistig und motorisch anspruchsvolles Abenteuer sorgen unsere speziellen Klettereinbauten, insbesonders bei hohen Turmskulpturen, welche jedesmal neu erdacht werden.</p>
            <p>Hier müssen Kletterwege gesucht und gefunden werden ohne dabei in Sackgassen zu geraten.</p>
            <p>"Rettungswege" sind vorhanden, die auch von Erwachsenen problemlos durchstiegen werden können, und wir achten natürlich auf die DIN Norm EN 1176.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <figure className="figure">
              <StaticImage
                src="../images/content/materialien.jpg"
                alt="Hochwertige Materialien"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Imposante Rutschen in der Züricher Innenstadt</figcaption>
            </figure>
          </div>
          <div className="col-sm">
            <h2>Verwendete Materialien</h2>
            <p className="lead">Langlebig, qualitätiv und handwerklich hochwertig</p>
            <p>In erster Linie verwenden wir für unsere Spielskulpturen bestes Eichenholz aus regionalen Quellen oder hochwertiges Robinienholz aus ungarischen Staatsforsten.</p>
            <p>Alle Metallkomponenten wie Rutschen, Schrauben, Pfostenschuhe, Gitter, etc. sind ausschliesslich aus V2A Edelstahl. Bei den Dächern verwenden wir zumeist Aluminium. <strong>Kein Plastik!</strong></p>
            <figure className="figure">
              <StaticImage
                src="../images/content/metallskulptur-libelle.jpg"
                alt="Metallskulptur Libelle"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Diese Prachtlibelle aus Edelstahl entstand in Zusammenarbeit mit jugendlichen Schülern</figcaption>
            </figure>
            <figure className="figure">
              <StaticImage
                src="../images/content/metallwerkstatt.jpg"
                alt="Metallwerkstatt"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">In unserer Metallwerkstätte fertigen wir außergewöhnliche künstlerische Produkte aus diversen Metallen</figcaption>
            </figure>
            <figure className="figure">
              <StaticImage
                src="../images/content/regionales-eichenholz.jpg"
                alt="Regionales Eichenholz"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Aus regionalen Eichenholz entstehen unsere massiven Tierskulpturen und Baumfrüchte</figcaption>
            </figure>
            <figure className="figure">
              <StaticImage
                src="../images/content/trockenkammer-robinie.jpg"
                alt="Robinie aus der Trockenkammer in massiver Skulptur verarbeitet"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Robinie aus der Trockenkammer zur massiven Skulptur verarbeitet</figcaption>
            </figure>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-4">
            <figure className="figure">
              <StaticImage
                src="../images/content/jedes-gelaende.jpg"
                alt="Wir meistern jedes Gelände"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Die "Gams" auf dem Wendelstein (ca. 1800m Höhe)</figcaption>
            </figure>
          </div>
          <div className="col-sm">
            <h2>Schwierige Arbeitsbedingungen</h2>
            <p className="lead">Wir meistern jedes Gelände</p>
            <p>Ob auf dem Wendelstein, im Hochgebirge, über wilde Gebirgsbäche, im und am See, im urbanen Großstadtumfeld in Parks und in der freien Landschaft - unsere Spielplätze sind überall zu finden.</p>

            <figure className="figure">
              <StaticImage
                src="../images/content/komplett-gestaltung.jpg"
                alt="Wir übernehmen die komplette Gestaltung Ihrer Anlagen"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Wir übernehmen die komplette Gestaltung Ihrer Anlagen</figcaption>
            </figure>
            <p>Selbstverständlich übernehmen wir auch alle anfallende Arbeiten für die komplette Gestaltung der ganzen Anlage.</p>
            <ul>
              <li>Aushub</li>
              <li>Fundamente</li>
              <li>Fallschutz</li>
              <li>Modellierung</li>
              <li>Entwässerung</li>
              <li>Natursteingestaltungen</li>
              <li>Einfassungen</li>
              <li>Pflasterung und Begrünung</li>
            </ul>

            <figure className="figure">
              <StaticImage
                src="../images/content/vormontage.jpg"
                alt="Vormontage in unseren Werkstätten"
                imgClassName="rounded"
              />
              <figcaption className="figure-caption">Um die Montagezeiten kurz zu halten werden auch größere Komponenten in unseren Werkstätten vorgefertigt</figcaption>
            </figure>
          </div>
        </div>

      </div>
    </Layout>
  );
}

export default AboutUsPage;